<!--
 * @Descripttion:选择修改问卷形式（提醒页）
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 14:39
-->
<template>
  <div class="question-publishing">
    <div class="title">请选择问卷修改形式</div>
    <div class="main">
      <div class="main-top" @click="handleKeepQuestion">
        <div class="main-title">保留答卷</div>
        <div class="main-content">不能对问卷进行：删除题目或选项，移动题目或选项，转换题型；只能简单地修改题目、增加选项</div>
      </div>
      <div class="main-bottom" @click="handleDeleteAllQuestion">
        <div class="main-title">删除所有答卷</div>
        <div class="main-content">删除所有答卷，修改问卷没有限制</div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import {releaseQuestionnaire, clearAllAnswer} from "@/api/questionnaire";
import globalQuestionnaire from '@/class/useQuestionnaire'

export default {
  name: "ModifyQuestion",
  methods: {
    handleKeepQuestion() {
      // globalQuestionnaire.changeKeepAnswerAndEdit(true)
      console.log('globalQuestionnaire', globalQuestionnaire)
      this.$router.push({
        path: `/edit/questionEdit/${this.$route.params.id}`,
        query: {
          isKeepAnswerAndEdit: true
        }
      })
    },
    async handleDeleteAllQuestion() {
      let params = {
        questionnaireProtoId: this.$route.params.id,
      }
      const res = await clearAllAnswer(params)
      if (res.status === 200 && res.data?.code === '00000') {
        this.$message.success('删除所有答卷成功')
        await this.$router.push({
          path: `/edit/questionEdit/${this.$route.params.id}`,
        })
      } else {
        this.$message.error('删除所有答卷失败，失败原因: ' + res.data.msg)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.question-publishing {
  margin-top: 50px;
  width: 40rem;
  height: 18rem;
  background: #FFFFFF;

  .title {
    width: 100%;
    height: 5rem;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main {
    width: 100%;
    height: 13rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .main-top {
      width: 70%;
      height: 5rem;
      .pointer();

      .main-title {
        font-weight: bold;
        font-size: 1rem;
      }
      .main-content {
        padding-top: 0.8rem;
        font-size: 0.5rem;
      }
    }
    .main-bottom {
      width: 70%;
      height: 5rem;

      .pointer();
      .main-title {
        font-weight: bold;
        font-size: 1rem;
      }
      .main-content {
        padding-top: 0.8rem;
        font-size: 0.5rem;
      }
    }
  }
}
</style>