/*
 * @Description: 个人中心api
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-16 16:36:48
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-08 17:21:24
 */
import service from '@/utils/request.js'

/**
 * 获取问卷列表
 * @param params {
 *  id: 问卷id （只传问卷id，可以拿到问卷基本信息）
 *  page: 页码
 *  size: 每页多少条数据
 * }  
 * @returns 
 */
export function getQuestionnaireList(params) {
  return service.request({
    url: '/mng/questionnaire-proto',
    method: 'get',
    params
  })
}

/**
 * 根据id获取问卷内容
 * @param {问卷id} id 
 * @returns 
 */
export function getQuestionnaireById(id) {
  return service.request({
    url: `/mng/questionnaire-proto/structure/${id}`,
    method: 'get',
  })
}

/**
 * 保存新建问卷
 * @param {问卷内容} data 
 * @returns 
 */
export function saveQuestionnaire(data) {
  return service.request({
    url: '/mng/questionnaire-proto',
    method: 'put',
    data
  })
}

/**
 * 保存编辑问卷
 * @param {问卷内容} data 
 * @returns 
 */
 export function saveEditQuestionnaire(data, questionnaireProtoId) {
  return service.request({
    url: `/mng/questionnaire-proto/structure/${questionnaireProtoId}`,
    method: 'post',
    data
  })
}

/**
 * 删除问卷（放在回收站）
 * @param data {
 *  questionnaireProtoId: "123",
 *  option: "SOFT_DELETE"
 * }  
 * @returns 
 */
export function deleteQuestionnaire(data) {
  return service.request({
    url: '/mng/file-hierarchy/questionnaire-proto',
    method: 'post',
    data
  })
}

/**
 * 导出问卷
 * @param 
 * @returns 
 */
export function exportQuestionnaire() {
  return process.env.VUE_APP_BASE_URL + '/mng/questionnaire-proto/export'
}

/**
 * 导出问卷分析页的答题
 * @param 
 * @returns 
 */
export function exportAnalyzeQuestionnaireText() {
  return process.env.VUE_APP_BASE_URL + '/mng/analyze/questionnaire/text/export'
}

/**
 * 导出问卷回收情况页的列表
 * @param 
 * @returns 
 */
export function exportAnalyzeQuestionnaireProto() {
  return process.env.VUE_APP_BASE_URL + '/mng/analyze/questionnaire-proto/export'
}

/**
 * 复制问卷
 * @param data{
 *  id: '123'
 * } 
 * @returns 
 */
export function copyQuestionnaire(data) {
  return service.request({
    url: '/mng/questionnaire-proto/duplicate',
    method: 'post',
    data
  })
}

/**
 * 发布问卷
 * @param  id 问卷id 
 * @returns 
 */
export function releaseQuestionnaire(id) {
  return service.request({
    url: `/mng/questionnaire-proto/release/${id}`,
    method: 'post'
  })
}

/**
 * 取消发布问卷
 * @param  id 问卷id 
 * @returns 
 */
 export function cancelReleaseQuestionnaire(id) {
  return service.request({
    url: `/mng/questionnaire-proto/cancel-release/${id}`,
    method: 'post'
  })
}

/**
 * 普通用户通过链接形式获取问卷
 * @param {*} id 
 * @returns 
 */
export function getGeneralQuestionnaireById(id) {
  return service.request({
    url: `/client/questionnaire-proto/${id}`,
    method: 'get',
  })
}

/**
 * 提交答卷
 * @param {*} data  答卷内容
 * @returns 
 */
export function submitAnswer(data) {
  return service.request({
    url: '/client/questionnaire-proto/submit',
    method: 'post',
    data
  })
}

/**
 * 删除所有答卷
 * @param {*} data  答卷内容
 * @returns
 */
export function clearAllAnswer(params) {
  return service.request({
    url: '/mng/questionnaire-proto/questionnaire/clear',
    method: 'post',
    params
  })
}

/**
 * 获取有效/无效问卷列表
 * @param params {
 *  questionnaireProtoId: 问卷原型id
 *  status: 有效问卷(VALID)/无效问卷(INVALID)
 *  page: 页码
 *  size: 每页多少条数据
 * } 
 * @returns 
 */
 export function getRecycleSituation(params) {
  return service.request({
    url: '/mng/analyze/questionnaire-proto',
    method: 'get',
    params
  })
}

export function getRecycleSituationFilter(query) {
  return service.request({
    url: `/mng/analyze/questionnaire-proto?${query}`,
    method: 'get'
  })
}

/**
 * 回收情况-获取答卷详情
 * 该接口，如果只传questionnaireId，查的是具体答卷信息
 * 如果传questionnaireProtoId，status和offset，查的也是具体答卷信息（针对答卷详情中的跳问卷功能）
 * @param params {
 *  questionnaireId:  答卷id（）
 * 
 *  questionnaireProtoId: 问卷原型id（）
 *  status: "VALID"        答卷状态
 *          "INVALID"
 *  offset: 1              答卷所在位置
 * }
 * @returns 
 */
export function getQuestionnaireWithAnswer(params) {
  return service.request({
    url: '/mng/analyze/questionnaire/detail',
    method: 'get',
    params
  })
}

/**
 * 操作问卷状态
 * @param params {
 *  questionnaireId: 答卷id
 *  option: "COMPLETE_DELETE"     删除无效答卷
 *          "MARK_INVALID"        标记无效答卷
 *          "RECOVERY"            标记有效答卷
 *          "CANCEL_BOOKMARK"     取消收藏
 *          "BOOKMARK"            收藏
 *          "REMARK"              备注答卷
 *  text: 备注内容
 * }  
 * @returns 
 */
 export function optionQuestionnaire(id, option, text) {
  const data = {
    questionnaireId: id,
    option: option,
    remark: text || '',
  }
  return service.request({
    url: '/mng/analyze/questionnaire/operate',
    method: 'post',
    data
  })
}

/**
 * 获取问卷分析报告
 * @param {*} questionnaireProtoId 
 * @returns 
 */
export function getReport(questionnaireProtoId) {
  return service.request({
    url: `/mng/analyze/questionnaire-proto/${questionnaireProtoId}/report`,
    method: 'get',
  })
}

/**
 * 获取答卷分析单选题、多选题、填空题、日期题填空部分
 * @param params {
 *  questionnaireProtoId, // 问卷原型id
 *  questionId,           // 问卷题目id
 *  choiceId,             // 题目选项id
 * }
 * @returns 
 */
export function getAnalysisDetail(params) {
  return service.request({
    url: '/mng/analyze/questionnaire/text',
    method: 'get',
    params
  })
}

/**
 * 获取问卷设置
 * @param {问卷id} id 
 * @returns 
 */
export function getQuestionnaireSettingById(id) {
  return service.request({
    url: `/mng/questionnaire-proto/setting/${id}`,
    method: 'get'
  })
}

/**
 * 保存问卷配置
 * @param {问卷id} id
 * @param {问卷内容} data 
 * @returns 
 */
 export function saveQuestionnaireSetting(id, data) {
  return service.request({
    url: `/mng/questionnaire-proto/setting/${id}`,
    method: 'post',
    data
  })
}

/**
 * 获取根据渠道回收数据
 * @param {string} id 问卷id
 */
export function getQuestionnaireSourceSummary(id) {
  return service.request({
    url: `/mng/analyze/questionnaire/summary/source?questionnaireProtoId=${id}`,
    method: 'get'
  })
}

/**
 * 获取根据时间回收数据
 * @param {string} id 问卷id
 * @param {string} startTime 开始时间
 * @param {string} endTime 结束时间
 */
 export function getQuestionnarieTimeSummary(id, startTime, endTime) {
  return service.request({
    url: `/mng/analyze/questionnaire/summary/time-range?startTime=${startTime}&endTime=${endTime}&questionnaireProtoId=${id}`,
    method: 'get'
  })
}

/**
 * 获取根据位置回收数据
 * @param {string} id 问卷id
 */
 export function getQuestionnarieLocationSummary(id) {
  return service.request({
    url: `/mng/analyze/questionnaire/summary/location?questionnaireProtoId=${id}`,
    method: 'get'
  })
}

/**
 * 获取中断问卷信息
 * @param {string} id 问卷id
 * @returns data {
 *  questionnaireProtoId: "123",
 *  collectSource: "PC",
 *  duration: 60
 *  answers: []
 * }
 */
export function getQuestionnaireInterrupt(id) {
  return service.request({
    url: `/client/questionnaire-proto/interrupt?questionnaireProtoId=${id}`,
    method: 'get'
  })
}

/**
 * 保存中断问卷信息
 * @param data {
 *  questionnaireProtoId: "123",
 *  collectSource: "PC",
 *  duration: 60
 *  answers: []
 * }  
 * @returns 
 */
export function saveQuestionnaireInterrupt(data) {
  return service.request({
    url: '/client/questionnaire-proto/interrupt',
    method: 'post',
    data
  })
}