<!--
 * @Descripttion:问卷发布中（提醒页）
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 14:00
-->
<template>
  <div class="question-publish">
    <div class="title">问卷发布中</div>
    <div class="main">
      <div class="main-top">发布中的问卷不允许进行编辑，请先暂停派发问卷。</div>
      <div class="main-bottom"><a-button class="suspend" @click="suspendPublish">暂停派发</a-button></div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import {cancelReleaseQuestionnaire} from '@/api/questionnaire'

export default {
  name: "QuestionPublishing",
  props: {
    total: Number,
  },
  data() {
    return {

    }
  },
  methods: {
    // 暂停派发
    async suspendPublish() {
      let questionId = this.$route.params.id
      const res = await cancelReleaseQuestionnaire(questionId)
      if (res.status === 200 && res.data?.code === '00000') {
        this.$message.success('暂停派发成功')
        console.log('total:', this.total)
        if (this.total === 0) {
          // 问卷总数为 0 跳转到编辑页
          this.$router.push(
              {
                path: `/edit/questionEdit/${questionId}`,
              }
          )
        } else {
          // 问卷总数不为 0 跳转到另外一提醒页
          this.$emit('suspendPublishSuccess')
        }
      }
    },
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
.question-publish {
  margin-top: 50px;
  width: 1300px;
  height: 881px;
  background: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 6px;

  .title {
    margin-top: 61px;
    width: 100%;
    height: 2rem;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    justify-content: center;
  }

  .main {
    width: 100%;
    height: 8rem;

    .main-top {
      width: 100%;
      margin: 48px 0 32px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 100;
    }
    .main-bottom {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      .suspend {
        width: 140px;
        height: 36px;
        line-height: 30px;
        font-size: 18px;
        background: #00A0E9;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
</style>