/*
 * @Description: 问卷类
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-30 11:43:00
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-23 17:38:19
 */
import { reactive, ref } from "@vue/reactivity";

class useQuestionnaire {
  constructor () {
    this.questionnaire = reactive({})
    this.isKeepAnswerAndEdit = ref(false) // 是否是保留答卷后再编辑 TODO: 这种方式不行，强刷就没了，用路由带上查询参数
    this.isLastPage = ref(false) // 答题时时是否为最后一页
    this.questionLibrary = ref([]) // 题库设置
    this.choiceLibrary = ref({}) // 选项库设置
  }
  
  // 设置问卷
  setQuestionnaire (newQuestionnaire) {
    this.clearQuestionnaire() // 先清空
    for (let key in newQuestionnaire) { // 后赋值
      if (key !== 'questions') {
        this.questionnaire[key] = newQuestionnaire[key]
      }
    }
    this.questionnaire.questions = []
    if (this.questionnaire.descriptionCSS) {
      this.questionnaire.description = typeof this.questionnaire.description === 'string' 
        ? JSON.parse(this.questionnaire.description)
        : this.questionnaire.description
    }
    let index = 0
    const questions = newQuestionnaire.questions
    const length = questions.length
    const timer = setInterval(() => {
      index = this.dealQusetion(questions, length, index, 10)
      if (index === length) {
        clearInterval(timer)
      }
    }, 100)
    // 判断这个问卷是否是只有一页
    this.isLastPage.value = questions.every(question => question.page === 0)
  }

  /**
   * 
   * @param {array} qusetions 问题
   * @param {number} length 问题长度
   * @param {number} index 初始位置
   * @param {number} count 渲染长度
   * @returns 
   */
  dealQusetion(qusetions, length, index, count) {
    let acc = 0
    for (index; index < length; index++, acc++) {
      if (count === acc) break
      const question = qusetions[index]
      if (question.titleCSS === 'true' && typeof question.title === 'string') {
        question.title = JSON.parse(question.title)
      } else if (question.questionType === 'PART' && this.questionnaire.partMap[question.part].descriptionCSS) {
        question.description = typeof question.description === 'string' ? JSON.parse(question.description) : question.description
        question.descriptionCSS = 'true'
        this.questionnaire.partMap[question.part].description = typeof this.questionnaire.partMap[question.part].description === 'string' 
          ? JSON.parse(this.questionnaire.partMap[question.part].description)
          : this.questionnaire.partMap[question.part].description
      }
      this.questionnaire.questions.push(question)
    }
    return index
  }
  
  // 获取问卷
  getQuestionnaire () {
    return this.questionnaire
  }

  // 清空问卷
  clearQuestionnaire () {
    for (let key in this.questionnaire) {
      delete this.questionnaire[key]
    }
  }

  // 添加题目
  addQuestion (question) {
    this.questionnaire.questions.push(question)
  }

  // 添加分页
  addPage (pageIndex) {
    this.questionnaire.pageMap[pageIndex] = `第${pageIndex+1}页`
  }

  // 添加章节
  addPart (partIndex, pageIndex) {
    let partContent = {
      'title': '',
      'description': '',
      'page': pageIndex,
    }
    this.questionnaire.partMap[partIndex] = partContent
  }

  // 更改是否是保留答卷后再编辑
  changeKeepAnswerAndEdit(bool) {
    this.isKeepAnswerAndEdit = bool
  }

  // 获取答题时是否为最后一页
  getIsLastPage() {
    return this.isLastPage
  } 

  // 设置答题时是否为最后一页
  setIsLastPage(bool) {
    this.isLastPage.value = bool
  }

  // 获取题库选中的值
  getQuestionLibrary() {
    return this.questionLibrary
  }

  // 获取选项库选中的值
  getChoiceLibrary() {
    return this.choiceLibrary
  }
}
const globalQuestionnaire = new useQuestionnaire()
export default globalQuestionnaire