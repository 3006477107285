<!--
 * @Descripttion:编辑问卷
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/30 15:14
-->
<template>
  <div class="edit-question">
    <question-publishing
      v-if="ifRelease"
      :total="total"
      @suspendPublishSuccess="handleSuspendPublishSuccess"
    ></question-publishing>
    <modify-question v-else></modify-question>
  </div>
</template>

<script>
import QuestionPublishing from "@/views/detail/editQuestion/components/QuestionPublishing";
import ModifyQuestion from "@/views/detail/editQuestion/components/ModifyQuestion";
import {getQuestionnaireList} from '@/api/questionnaire'

export default {
  name: "EditQuestion",
  components: {
    QuestionPublishing,
    ModifyQuestion,
  },
  data() {
    return {
      // isSuspend: false, // 问卷是否已经暂停派发
      ifRelease: false, // 问卷是否已经暂停派发
      // title: '', // 问卷名称
      total: 0, // 问卷总数
    }
  },
  methods: {
    handleSuspendPublishSuccess() {
      this.getQuestionInfo()
    },
    async getQuestionInfo() {
      let params = {
        id: this.$route.params.id,
      }
      const res = await getQuestionnaireList(params)
      if (res.status === 200 && res.data?.code === '00000') {
        let content = res.data.data.content
        let question = content[0]
        this.total = question.inValidQuestionnaireNum + question.validQuestionnaireNum
        this.ifRelease = question.ifRelease
        // 如果问卷是暂停派发，且问卷总数为0，则直接跳转至编辑界面  
        if (this.total === 0 && !this.ifRelease) this.$router.push(`/edit/questionEdit/${question.id}`)
      }
    },
  },
  created() {
    this.getQuestionInfo()
  }
}
</script>

<style lang="less" scoped>
.edit-question {
  display: flex;
  justify-content: center;
}
</style>